<template>
  <div class="driver-point-detail">
    <div ref="ActionBar" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="85px"
      >
        <el-form-item label="积分日期起：">
          <el-date-picker
            v-model="form.integralTimeStart"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择时间"
            @clear="form.integralTimeStart = null"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="积分日期止：">
          <el-date-picker
            v-model="form.integralTimeEnd"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择时间"
            @clear="form.integralTimeEnd = null"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="手机号码：">
          <el-input
            v-model="form.driverPhone"
            placeholder="请输入手机号码"
            maxlength="13"
            clearable
            @clear="form.driverPhone = null"
          ></el-input>
        </el-form-item>
        <el-form-item label="司机姓名：">
          <el-input
            v-model="form.driverName"
            placeholder="请填写司机姓名"
            maxlength="5"
            clearable
            @clear="form.driverName = null"
          ></el-input>
        </el-form-item>

        <el-form-item label="所属公司：">
          <companySelect
            ref="companySelect"
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="onSearch"
            >查询</el-button
          >
          <el-button
            v-if="listFind('导出')"
            type="primary"
            size="small"
            @click="exportFun"
            >导出</el-button
          >
          <!-- <el-button
            v-if="listFind('拉新')"
            type="primary"
            size="small"
            @click="exchangeFun"
            >拉新积分兑换</el-button
          > -->
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :table-height="tableHeight"
      operation-width="100"
    >
      <template slot-scope="{ scopeRow }">
        <div>
          <el-button
            v-if="
              listFind('明细') &&
              [1, 2, 3, 4].includes(scopeRow.integralProject)
            "
            type="text"
            size="small"
            sort="primary"
            style="color: #ff8627"
            @click="toDetail(scopeRow.passengerEvaluationId)"
            >积分明细</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <charter-popup
      v-if="showForm"
      header-text="积分兑换"
      width="750"
      :disabled="true"
      max-height="650px"
      @closePopupFun="showForm = false"
    >
      <div ref="driverPointConversion" class="driver-point-conversion">
        <el-form
          :inline="true"
          label-width="80px"
          class="form-area"
          :model="ruleForm"
        >
          <el-form-item label="所属公司：">
            <companySelect
              :clearable="true"
              :company-tree="companyList"
              :valuename="valueName"
              :value="value"
              @getValue="getGroupId"
            />
          </el-form-item>
          <el-form-item label="司机姓名：">
            <el-input
              v-model="ruleForm.userName"
              clearable
              placeholder="请填写司机姓名"
              @clear="ruleForm.userName = null"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="queryFun"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
        <Table
          :table-data="tableData2"
          :title-name="titleName2"
          :operation="true"
          :col-class-type="true"
          :table-height="600"
          operation-width="80"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                v-if="listFind('兑换')"
                type="text"
                size="small"
                sort="primary"
                style="color: #ff8627"
                @click="conversionFun(scope.scopeRow)"
                >兑换</el-button
              >
            </div>
          </template>
        </Table>
        <div ref="pagination" class="pagination">
          <el-pagination
            layout="total, prev, pager, next"
            :total="ruleForm.total"
            :page-size="5"
            :current-page="ruleForm.currentPage"
            @current-change="onCurrentChange2"
          ></el-pagination>
        </div>
        <!--  -->
        <div class="conver">
          <el-row>
            <el-col :span="10"
              ><span>可用用户积分：</span>
              <el-input v-model="conversionUser.activityPoints" disabled
            /></el-col>
            <el-col :span="14"
              ><span>兑换比例：</span>
              <el-input v-model="conversionUser.rate" disabled />
              <p>（用户积分：司机积分）</p>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10"
              ><span>兑换用户积分：</span>
              <el-input-number
                v-model="exchangePoints"
                size="small"
                :min="0"
                :max="conversionUser.activityPoints"
                @change="computedIntegral"
              ></el-input-number>
            </el-col>
            <el-col :span="14"
              ><span>可得司机积分：</span>
              <el-input v-model="driverIntegral" disabled
            /></el-col>
          </el-row>
          <div class="buttom">
            <el-button size="small" type="primary" @click="confirmFun"
              >立即兑换</el-button
            >
          </div>
        </div>
      </div>
    </charter-popup>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  queryDriverIntegralPageAPI,
  exportDriverIntegralAPI,
  getCompanyTree,
  getDriverList,
  queryDriverIntegralInfoAPI,
  InviteNewUsersAPI,
  queryDriverInviteNewUserIntegralConvertInfoAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data() {
    return {
      titleName: [
        {
          title: "积分日期",
          props: "integralTime",
          width: 100,
          SpecialJudgment: (res) => {
            if (res) {
              return res.slice(0, 10);
            } else {
              return "-";
            }
          },
        },
        {
          title: "司机手机号码",
          props: "driverPhone",
          width: 120,
        },
        {
          title: "司机姓名",
          props: "driverName",
        },
        {
          title: "积分项目",
          props: "integralProject",
          width: 110,
          SpecialJudgment: (res) => {
            return this.integralProjectList.find((e) => e.value == res)?.label;
          },
        },
        {
          title: "积分",
          props: "integral",
        },
        {
          title: "备注",
          props: "remark",
        },
        {
          title: "入账时间",
          props: "createTime",
          SpecialJudgment: (res) => {
            if (res) {
              return res.slice(0, 16);
            } else {
              return "-";
            }
          },
          width: 150,
        },
        {
          title: "所属公司",
          props: "companyName",
          width: 150,
        },
      ],
      integralProjectList: [
        {
          label: "用户好评",
          value: 1,
        },
        {
          label: "用户中评",
          value: 2,
        },
        {
          label: "用户差评",
          value: 3,
        },
        {
          label: "投诉",
          value: 4,
        },
        {
          label: "违规操作",
          value: 5,
        },
        {
          label: "拉新积分兑换",
          value: 6,
        },
        {
          label: "实载率达标",
          value: 7,
        },
        {
          label: "实载率基础分",
          value: 8,
        },
        {
          label: "服务满意度基础分",
          value: 9,
        },
        {
          label: "拉新积分",
          value: 10,
        },
      ],
      startList: [],
      endList: [],
      tableData: [],
      companyList: [],
      valueAddName: "",
      valueAdd: 0,
      form: {
        currentPage: 1,
        pageSize: 10,
        driverPhone: null,
        driverName: null,
        integralTimeStart: null,
        integralTimeEnd: null,
        companyIds: null,
      },
      tableHeight: 600,
      showForm: false,
      ruleForm: {
        companyIds: null,
        userName: null,
        driveType: 0,
        currentPage: 1,
        pageSize: 5,
        total: 0,
      },
      valueName: null,
      value: null,
      tableData2: [],
      titleName2: [
        { title: "登录手机号", props: "loginPhone" },
        { title: "联系号码", props: "phone" },
        { title: "司机姓名", props: "userName" },
        { title: "所属企业", props: "companyName" },
      ],
      conversionUser: {
        activityPoints: 0,
        memberId: null,
        rate: null,
      },
      driverUser: {},
      rate: null,
      driverIntegral: 0,
      exchangePoints: 0,
      showConver: true,
    };
  },
  mounted() {
    let date = new Date();
    var daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    var strYear = date.getFullYear();
    var strMonth = date.getMonth() + 1;

    if ((strYear % 4 === 0 && strYear % 100 !== 0) || strYear % 400 === 0) {
      daysInMonth[2] = 29;
    }
    var strDay = daysInMonth[strMonth - 1];
    this.form.integralTimeStart = strYear + "-" + strMonth + "-01 00:00:00";
    this.form.integralTimeEnd =
      strYear + "-" + strMonth + "-" + strDay + " 23:59:59";
    this.computeHeight();
    this.exportForm = this.deepClone(this.form);
    this.renderData();
    this.getCompanyList();
  },
  computed: {
    computeResult: function () {
      return 0;
    },
  },
  methods: {
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    getAddGroupId(v) {
      if (v) {
        this.form.companyIds = [v];
      } else {
        this.form.companyIds = null;
      }
    },
    getGroupId(v) {
      if (v) {
        this.ruleForm.companyIds = [v];
      } else {
        this.ruleForm.companyIds = null;
      }
    },
    // 计算表格高度
    computeHeight() {
      const ActionBarHeight = this.$refs.ActionBar.clientHeight;
      this.TableHeight = `calc(100vh - ${ActionBarHeight + 280}px)`;
    },
    // 拉新积分兑换
    exchangeFun() {
      this.showForm = true;
      this.ruleForm = {
        companyIds: null,
        userName: null,
        driveType: 0,
        currentPage: 1,
        pageSize: 5,
        total: 0,
      };
      this.conversionUser = {
        activityPoints: 0,
        memberId: null,
        rate: null,
      };
      this.driverUser = {};
      this.$nextTick(() => {
        queryDriverInviteNewUserIntegralConvertInfoAPI({
          channelNo: this.$configs.channelNo,
        }).then((res) => {
          if (res.code == "SUCCESS") {
            this.conversionUser.rate = `${res.data.convertRatioStart}:${res.data.convertRatioEnd}`;
            this.rate = res.data.convertRatioEnd / res.data.convertRatioStart;
          }
        });
        this.getDriverList();
      });
    },
    onSearch() {
      if (this.form.integralTimeStart) {
        if (this.form.integralTimeStart.indexOf(" 00:00:00") < 0)
          this.form.integralTimeStart =
            this.form.integralTimeStart + " 00:00:00";
      } else {
        this.form.integralTimeStart = null;
      }
      if (this.form.integralTimeEnd) {
        if (this.form.integralTimeEnd.indexOf(" 23:59:59") < 0)
          this.form.integralTimeEnd = this.form.integralTimeEnd + " 23:59:59";
      } else {
        this.form.integralTimeEnd = null;
      }
      this.exportForm = this.deepClone(this.form);
      this.renderData();
    },
    renderData() {
      queryDriverIntegralPageAPI(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.form.total = res.data.total;
        }
      });
    },
    // 跳转到评价管理页面
    toDetail(id) {
      this.$router.push(`/projectTravel/evaluationManage?id=${id}`);
    },
    exportFun() {
      exportDriverIntegralAPI(this.form).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "司机积分明细 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 当前分页改变
    onCurrentChange(val) {
      this.form.currentPage = val;
      this.onSearch();
    },
    // 分页大小改变
    onSizeChange(val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.onSearch();
    },
    getDriverList() {
      getDriverList(this.ruleForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData2 = res.data.list;
          this.ruleForm.total = res.data.total;
        }
      });
    },
    queryFun() {
      this.ruleForm.currentPage = 1;
      this.getDriverList();
    },
    // 当前分页改变
    onCurrentChange2(val) {
      this.ruleForm.currentPage = val;
      this.getDriverList();
    },
    conversionFun(raw) {
      this.driverUser = {
        companyId: raw.companyId,
        companyName: raw.companyName,
        driverId: raw.id,
        driverName: raw.userName,
        driverPhone: raw.phone,
        integralType: 1,
        integralProject: 6,
        channelNo: this.$configs.channelNo,
      };
      queryDriverIntegralInfoAPI(raw.phone).then((res) => {
        if (res.code == "SUCCESS") {
          if (res.data) {
            this.conversionUser.activityPoints = res.data.activityPoints;
            this.conversionUser.memberId = res.data.memberId;
          }
        }
      });
    },
    computedIntegral(val) {
      this.driverIntegral = val * this.rate;
    },
    confirmFun() {
      this.driverUser.integral = this.driverIntegral;
      this.driverUser.inviteIntegral = this.exchangePoints;
      if (!this.driverUser.driverId) {
        this.$message.warning("请选择司机用户");
        return;
      }
      if (!this.driverUser.inviteIntegral) {
        this.$message.warning("兑换积分不能为0");
        return;
      }
      InviteNewUsersAPI(this.driverUser).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("拉新积分兑换成功");
          this.renderData();
          this.showForm = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-point-detail {
  background: #fff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  .header {
    padding: 10px;
  }
  .form-area {
    margin-bottom: 10px;
  }
  .driver-point-conversion {
    margin: 20px;
    /deep/.el-form {
      .el-input {
        width: 150px;
      }
      .el-form-item {
        margin-right: 40px;
      }
    }
    .conver {
      margin: 20px;
      .el-row {
        margin-bottom: 20px;
        .el-col {
          display: flex;
          align-items: center;
        }
        span {
          display: block;
          width: 100px;
          text-align: right;
        }
        /deep/.el-input {
          width: 130px;
        }
      }
      .buttom {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
